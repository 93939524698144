/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */

'use client';

/**
 * Why are we doing this instead of using react-hook-intercom
 * https://github.com/devrnt/react-use-intercom/issues/649
 *
 * TL;DR when changing lang Intercom loose track of the document element
 * and crash so we manually reset the Intercom Client on each navigation
 * This is not perfect, but it works.
 */

import { useLayoutEffect } from 'react';

import { useAuthContext } from '@/app/[locale]/_providers/auth';
import clientConfig from '@/lib/config/client-config';

import { useBreakpoint } from '@/lib/style/hooks';
import { usePathname } from '@/lib/translation/hooks';

function computeIntercomVerticalPaddingFromPathname(
  pathname: string,
  isDesktop: boolean,
): number {
  if (pathname.includes('/invest/')) {
    return isDesktop ? 85 : 180;
  }
  return 20;
}

export function shutdownIntercom() {
  // Remove the iframe
  if (typeof window.Intercom === 'function') {
    window.Intercom('shutdown');
  }

  const container = document.getElementById('intercom-container');
  const cssContainer = document.getElementById('intercom-css-container');
  const frame = document.getElementById('intercom-frame');

  if (container) document.body.removeChild(container);
  if (cssContainer) document.body.removeChild(cssContainer);
  if (frame) document.body.removeChild(frame);
}

export function openIntercom() {
  // Open the iframe
  if (typeof window.Intercom === 'function') {
    window.Intercom('show');
  }
}

const IntercomClient = ({ userHash }: { userHash: string | null }) => {
  const pathname = usePathname();
  const isDesktop = useBreakpoint('md');
  const { user } = useAuthContext();

  useLayoutEffect(() => {
    if (!clientConfig.intercom.enabled) {
      // eslint-disable-next-line no-empty-function
      return () => {};
    }
    // Reset
    shutdownIntercom();
    // Create script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://widget.intercom.io/widget/${clientConfig.intercom.appId}`;
    document.body.appendChild(script);

    window.Intercom = function () {
      // @ts-expect-error currently defining function
      window.Intercom.c(arguments);
    };
    // @ts-expect-error currently defining function
    window.Intercom.q = [];
    // @ts-expect-error currently defining function

    window.Intercom.c = function (args: unknown) {
      // @ts-expect-error currently defining function
      window.Intercom.q.push(args);
    };

    let intercomOptions = {};

    if (userHash && user?.email) {
      intercomOptions = {
        app_id: clientConfig.intercom.appId,
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: computeIntercomVerticalPaddingFromPathname(
          pathname,
          isDesktop,
        ),
        email: user.email,
        user_hash: userHash,
      };
    } else if (user?.email) {
      intercomOptions = {
        app_id: clientConfig.intercom.appId,
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: computeIntercomVerticalPaddingFromPathname(
          pathname,
          isDesktop,
        ),
        email: `${user.email}`,
      };
    } else {
      intercomOptions = {
        app_id: clientConfig.intercom.appId,
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: computeIntercomVerticalPaddingFromPathname(
          pathname,
          isDesktop,
        ),
      };
    }

    // Add iframe
    // @ts-expect-error currently defining function
    window.Intercom('boot', intercomOptions);
    return () => {
      document.body.removeChild(script);
      shutdownIntercom();
    };
  }, [pathname, isDesktop, user, userHash]);

  return null;
};

export default IntercomClient;
