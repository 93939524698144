'use client';

import { useTranslations } from 'next-intl';

import { logout } from '@/app/_components/header/action';
import { DropdownMenuItem } from '@/app/_components/ui/dropdown-menu';
import { shutdownIntercom } from '@/lib/intercom/client';

const LogoutComponent = () => {
  const t = useTranslations();
  const handleLogout = async () => {
    // Here we need to manually call the intercom shutdown, because it must remove its own cookies before logging out the user
    shutdownIntercom();
    await logout();
  };

  return (
    <DropdownMenuItem
      className="cursor-pointer px-2 text-base"
      onClick={() => handleLogout()}
    >
      {t('auth.logout')}
    </DropdownMenuItem>
  );
};

export default LogoutComponent;
